<template>
<div>
	<el-dialog :title="dialogTitle" :visible.sync='dialogAddVisible' width="60%" :close-on-click-modal="false" :modal-append-to-body="false" validate-on-rule-change="true" >
		<el-form  label-position="top"  ref="form" :model="form" prop="" >	
				<el-row class="field-content">
						<div v-for="(item,childIndex) in formData" >
						  <el-col :span="8"  v-if="item.type !== 1 && item.type !== 4"> 
							  <el-form-item  :label="item.title" :required="item.required_status?true:false">  
								  	<!-- 普通文本 -->
									<el-input  v-if="item.type < 1" style="width: 280px;" 
										v-model="item.name?item.name: item.value" 
										:disabled="item.field_name ==='receipt_number' || item.disabled?true:false" 
										:placeholder="item.field_name ==='receipt_number'?'单据自动生成':item.title" ></el-input>
									<!-- 金额 -->
									 <el-input-number  v-if="item.type == 11" v-model="item.value" :precision="2" :step="0.1" :min="1"  controls-position="right"></el-input-number>
									<!-- 下拉框 -->
									<!-- 部门、成员数据下拉 -->
									<template  v-if="item.type == 9">
										<el-cascader
											 v-if="item.field_name == 'dept_id'"
											v-model="item.value"   
											 :props="props"  
											:options="deptList"  
											  @change="handleChangeDept"
											  ref="cascader"  
											></el-cascader> 
										<!-- 部门成员 -->
										<el-select filterable v-else-if="item.field_name == 'user_id'" v-model="item.value"   placeholder="请选择"  style="width: 280px;">
												 <el-option
												  v-for="admin in adminList"
												 :label="admin.name"
												 :value="admin.id">
												 </el-option>
										</el-select>
										<!-- 调出/调入管理员 -->
										<el-select filterable  v-else-if="item.field_name == 'out_admin_id' 
												|| item.field_name == 'in_admin_id'" 
												v-model="item.value"   
												 placeholder="请选择"  style="width: 300px;">										
												 <el-option
												 v-for="admin in adminList"
												:label="admin.name" 
												:value="admin.id">
												</el-option> 
										</el-select>
										<el-select  v-if="selectionStatus && item.field_name == 'location_id'" 
											style="width: 280px;"
											ref="treeSelect"
											v-model="item.temp_select_name" placeholder="请选择" clearable 
											 @change="(val)=>handleSelect(val,index,childIndex)">
												<el-option value="0" label="无上级">无上级</el-option>
												<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
												 highlight-current ref="tree" 
												 @node-click="(val)=>handleTreeNodeClick(val,childIndex)"> </el-tree>
										</el-select>
										<el-select v-if="
													item.field_name !== 'out_admin_id' 
													&& item.field_name !== 'in_admin_id'
													&&  item.field_name !== 'dept_id'
													&&  item.field_name !== 'user_id'
													&&  item.field_name !== 'location_id'
													" v-model="item.value"   placeholder="请选择"  style="width: 280px;">
										  <el-option v-for="(cItem,cIndex) in item.content" :label="cItem.name" :value="cIndex">
										  </el-option>
										</el-select>
									</template>
									<!-- 日期选择 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 6"
										v-model="item.value"
										type="date"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 时间日期 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 5"
										v-model="item.value"
										type="datetime"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 金额 -->
									<el-input-number v-if="item.type == 1" v-model="item.value" :precision="2" :step="0.1" :max="10"></el-input-number>
							  </el-form-item>   
						  </el-col>
						   <el-col :span="24" v-if="item.type == 1 || item.type == 4">
							   <!-- 多行文本 -->
							   <el-form-item v-if="item.type == 1" :label="item.title"
									:required="item.required_status?true:false">
									<el-input type="textarea" rows="5"  v-model="item.value" placeholder="" ></el-input>
							   </el-form-item> 
							   <el-form-item v-if="item.type == 4" :label="item.title" 
									:required="item.required_status?true:false">
										<el-upload
										  action="https://jsonplaceholder.typicode.com/posts/"
										  list-type="picture-card"
										 >
										  <i class="el-icon-plus"></i>
										</el-upload>
								</el-form-item> 						
							</el-col>
						  </div>
				</el-row>
		</el-form>
		<!-- :row-key="getRowKeys" -->
		 <!-- @selection-change="handleSelectionChange" -->
		 <!-- && cardName != '资产领用' && cardName != '资产借用'  && cardName != '资产采购'" -->
		 <template>
			<p class="select-asset" 
			v-if="cardName != '资产领用' 
			&& cardName != '资产借用'  
			&& cardName != '资产采购' 
			&& cardName != '耗材领用'"
			@click="handleSelectAsset">
				{{applyType==1?'选择资产':'选择耗材'}}
			</p>
			
			<el-table
				class="storage-table-box"
				border
				:data="tableData" 
				:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
				:row-style="{height:'40px'}"
				 :cell-style="{padding: '0'}" 
				style="width: 100%;">
				<template v-for="item in theadData">
					<el-table-column :key="item.field_name" :label="item.title">
						<template slot-scope="scope">
							<span 
							v-if="cardName != '资产领用' 
							&& cardName != '资产借用'  
							&& cardName != '资产采购'
							&& cardName != '耗材领用'
							&& item.title !== '采购金额' 
							&& item.title !== '采购数量'
							&& item.title !== '申请数量'
							&& item.title !== '退还数量'">{{scope.row[item.field_name]}}</span>
							<!-- <reqFormVue ref="selectCse" v-bind:formData="item" :cate="cateTreeData" v-if="Object.keys(cateTreeData).length > 0"></reqFormVue> -->
							<div class="add-table-row-box" v-else> 
							<template >	
							
								<el-input  v-if="item.type < 1" style="width: 280px;" v-model="scope.row[item.field_name]" :placeholder="item.title" ></el-input>
								<template v-if="item.field_name == '采购金额'"> 
									<el-input-number   
								
									v-if="item.type == 11" 
									v-model="scope.row[item.field_name]" 
									:precision="2" :step="0.1" 
									:min="0.00"  
									:controls="false"></el-input-number>
								</template>
								<template v-else>
									<el-input-number v-if="item.type == 11" @change="changeNumber(scope.$index,scope.row)"   v-model="scope.row[item.field_name]"  :min="0"  :controls="false"></el-input-number>
								</template>
								<template  v-if="item.type == 9">
									<el-cascader
										 v-if="item.field_name == 'dept_id'"
										v-model="scope.row[item.field_name]"   
										 :props="props"  
										:options="deptList"  
										  @change="handleChangeDept"
										  ref="cascader"  
										></el-cascader> 
									<el-select  v-if="item.field_name == 'cate_id'"  ref="treeSelect"
										v-model="scope.row['cate_name']" placeholder="请选择" clearable 
										 @change="(val)=>handleSelect(val,index,childIndex)">
											<el-option value="0" label="无上级">无上级</el-option>
											<el-tree   node-key="id" :data="cateTreeData" :props="treePorps"
											 highlight-current ref="tree" 
											 @node-click="(val)=>handleNodeClick(val,scope.$index,'cate_id')"> </el-tree>
									</el-select>
								</template>
								
							</template>
							</div>
						</template>
					</el-table-column>
				</template>
				<el-table-column
					label="操作" 
					>
					<template slot-scope="scope">
						<el-button
							  @click.native.prevent="addNewRow(scope.$index, tableData)"
							  type="text"
							  size="small">
							  添加
						</el-button> 
						 <el-button
							  @click.native.prevent="deleteRow(scope.$index, tableData)"
							  type="text"
							  size="small">
							  移除
						</el-button> 
					</template> 
				</el-table-column>
			</el-table> 
		 </template>
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialogAddVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
		<SelecteAssetVue ref="selectAsset" :tableData.sync="tableData"></SelecteAssetVue>
		<SelecteCseVue ref="selectCse" :tableData.sync="tableData"></SelecteCseVue>
	</el-dialog>
</div>
</template>

<script>
import SelecteAssetVue from '../Form/SelectAsset.vue'
import SelecteCseVue from '../Form/SelectConsumable.vue'
import reqFormVue from './ReqForm.vue'
export default {
	inject:['reload'],
	components:{
	    SelecteAssetVue,
		SelecteCseVue,
		reqFormVue
	},
    data() {
        return {
			applyType:1,		//1-资产 2-耗材
			dialogVisible:false,
			checkboxGroup:[],
			dialogAddVisible:false,
			selectionStatus:true,
			cateTreeData:[],
			locationTreeData:[],
			groupData:[], 
			fieldData:[],
			cardName:'',
			cardType:4,
			form:{},
			theadData:[],
			tableData:[],
			deptList:[],
			deptId:'',
			dialogTitle:'',
			addUrl:'',
			editUrl:'',
			storageType:0,
			assetCodeStatus:false,
			newRow:{},
			treePorps:{
				label:'name',
			},
			props:{
				value:'id',
				label:'name',
			},
			tableStatus:true,
			adminList:[],		//管理员列表
			staffStorageId:0,		//员工申请的单据id 传递时代表从员工单据里请求操作 后续绑定关联
			admin_status:1,
			formData:[]
        }
    },
    created() {

    },
	watch: {
		'tableData'() {
			this.tableStatus =  false
			this.$nextTick(()=>{
				this.tableStatus  = true
				
			})
		},
	},
    mounted() {
		
    },
    methods:{
		//监听数量 获取总数量
		changeNumber(index,row){
			var total_prices  = 0.00
			var total_count = parseInt(0)
			
			this.tableData.forEach((item,index)=>{
				if(item.number > 0){
					total_count = total_count + parseInt(item.number)
				}	
			})
			console.log('total_count:',total_count)
			console.log('table:',this.tableData)
			this.formData.forEach((item,index)=>{
				if(item.field_name == 'total_count'){
					item.value =  total_count
				}
			})
			
			this.formData = JSON.parse(JSON.stringify(this.formData))
			console.log(this.formData)
		},
		
		deleteRow(index, rows) {
			//重新计算总数量
			this.formData.forEach((item)=>{
				if(item.field_name == 'total_count'){
					item.value =  item.value - this.tableData[index]['number']
				}
			})
			this.formData = JSON.parse(JSON.stringify(this.formData))
			
			rows.splice(index, 1);
			
			
		},
		changeNumber2(index,price,number){
			let total_prices =  price*number
			this.changeTable = false
			this.$nextTick(()=>{
				this.changeTable =  true
				this.tableData[index]['total_prices'] = total_prices
				
				this.calcAmount()
			})		
		},
		calcAmount(){		//计算操作资产总价
			 this.total_prices = 0
			this.tableData.forEach((item)=>{		//合计金额
				 if(item.total_prices){
					 this.total_prices += Math.ceil(item.total_prices * 100) / 100
				 }
			})
			this.formData.forEach((item,index)=>{
				if(item.field_name == 'total_amount'){
					this.formData[index]['value'] = Math.ceil(this.total_prices * 100) / 100
				}
			})
			
			console.log(this.groupData)
		},
		
		handleAdd(){ 
				// console.log('s_type:',this.storageType,',card_name:',this.cardName) 
				this.dialogTitle =  '添加'+this.cardName
				this.$api.get('AssetForm/getFieldAndStaffTable', {
					name:this.cardName,
					type:this.cardType
				}, res => {
					if (res.code == 200) {
						// this.groupData =  res.data.group_data
						this.formData =  res.data.group_data
						// this.formData.forEach((item)=>{
						// 	if(item.field_name == 'total_count'){
						// 		item.value = 1
						// 	}
						// })
						this.theadData =  res.data.thead_data
			
						if(this.staffStorageId < 1 || this.tableData.length < 1){
							this.tableData =  res.data.empty_table_data
							
						}
						this.newRow =  res.data.empty_table_data[0]
						
						this.assetCodeStatus = res.data.asset_code_status?true:false	
									
						this.checkboxGroup =  res.data.default_checked
						this.$api.get('Department/deptList',null,deptRes=>{
							if(deptRes.code == 200){
								this.deptList =  deptRes.data.list
							}
						})
						
						//获取资产分类
						this.$api.get('AssetCategory/treeData',{
							type:this.applyType
						},res=>{
						    if(res.code == 200){
								this.cateTreeData =  res.data.tree_list
							}
						})
						
						//管理员列表
						this.$api.get('admin/getDeptAdmin',null,res=>{
						     if(res.code == 200){
								this.adminList =  res.data.list
						     }else{
						         this.$message.error(res.msg)
						     }
						 })
						
						//获取资产位置
						this.$api.get('AssetLocation/treeData',{
							type:this.applyType
						},res=>{
						    if(res.code == 200){
								this.locationTreeData =  res.data.tree_list
								// console.log('location:',this.locationData)
							}
						})
						this.dialogAddVisible = true
					}
				})
		},
		
		
		//添加新行
		addNewRow(index,table_data){
			this.tableStatus = false
			this.$nextTick(()=>{
				this.tableStatus = true
				this.tableData.push(this.newRow)
			})
		},
		
		//监听资产分类/位置选择
		handleNodeClick(val,index,name){
			this.tableStatus = false
			this.$nextTick(()=>{
					this.tableStatus = true
					if(name == 'location_id'){
						this.tableData[index]['location_name'] = val.name
						this.tableData[index]['location_id'] = val.id
					}
					if(name == 'cate_id'){
						this.tableData[index]['cate_name'] = val.name
						this.tableData[index]['cate_id'] = val.id
					}
					if(name == 'dept_id'){
						this.tableData[index]['dept_name'] = val.name
						this.tableData[index]['dept_id'] = val.id
						
						this.$api.get('admin/getDeptAdmin',{dept_id:val.id},res=>{
						     if(res.code == 200){
								this.adminList =  res.data.list
						     }else{
						         this.$message.error(res.msg)
						     }
						 })
					}
					this.tableData = JSON.parse(JSON.stringify(this.tableData))
			})
			
		},
		handleTreeNodeClick(data,childIndex){
			
			this.selectionStatus =  false
			this.$nextTick(()=>{
				this.selectionStatus =  true
				this.formData[childIndex]['value'] = data.id
				this.formData[childIndex]['temp_select_name'] = data.name
				this.location_id = data.id
				// console.log('groupdata:',this.groupData)
			})
		},
		handleSelectAsset(){
			let status =  true
			this.formData.forEach((item)=>{
				if(item.field_name == 'location_id' || item.field_name == 'in_location_id' || item.field_name == 'out_location_id') {
					// item.required_status && 
					if(!item.value){
						status = false
					}
				}
			})
			if(!status){
				let msg = this.applyType == 1?'请先选择资产位置':'请先选择仓库'
				 this.$message.error(msg)
				 return false
			}
			this.$nextTick(()=>{
				
				if(this.applyType == 1){	//选择资产
					this.$refs.selectAsset.storageType =  this.storageType
					this.$refs.selectAsset.showAsset(this.tableData) 
				}else{	//选择耗材
					this.$refs.selectCse.form.type =  this.storageType
					this.$refs.selectCse.form.mtl_code_data =  this.mtlCodeData
					this.$refs.selectCse.showAsset(this.tableData,this.location_id)
				}
				
			})
		
		},
		
		
		//监听部门选择 更新部门成员数据
		handleChangeDept(value){
			let dept_id = value[value.length-1]    
			 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
				  if(res.code == 200){  
						this.adminList =  res.data.list
				  }else{
					  this.$message.error(res.msg)
				  } 
			  })
		},
		//获取提交接口
		getAddUrl(add_status = true){
			var type =  this.storageType
			var url = ''
			switch(type){
				case 2:		//派发
				case 3:		//退库
					url = 'AssetDist/add'
					break;
				case 4:		//借出
				case 5:		//归还
					url = 'AssetLoan/add'
					break;
				case 6:		//变更
					url = 'AssetChangeUser/add'
					break;
				case 7:		//调拨
					url = 'AssetAllot/add'
					break;
				// case 8:		//维修
				// 	url = 'AssetDist/add'
				// 	break;
				case 9:		//处置
					url = 'AssetDisposal/add'
					break; 
				// case 9:		//修改
				// 	url = 'AssetDist/add'
				// 	break;
			}
			
			return url
		},
		confirm(){
			// console.log('confirm:', this.storageType)
			this.form.field_data =  this.formData
			this.form.checked_list = this.tableData
			this.form.type =  this.storageType
			this.form.card_name =  this.cardName
			// this.form.staff_storage_id = this.staffStorageId
			
			this.$api.post('Staff/add', this.form, res => { 
				if (res.code == 200) {
					this.dialogAddVisible = false
					this.$message.success(res.msg)
					this.reload();
					this.$refs["form"].resetFields();
		  
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleChangeSelect(val){
			this.deptId  = val
		},
		
    },
}
</script>
<style lang="scss">
	.storage-table-box{
		.cell{
			padding: 0 !important;
			text-align: center;
		}
		.add-table-row-box{
			// width: 120px;
			.el-input__inner{
				border: none !important;
			}
		}
	}


</style>
<style scoped lang="scss">
	
	.select-asset{
		color: #409EFF;
		font-weight: 600;
		font-size: 14px;
		text-align: right;
	}
	
</style>