<template>
<div class="overview-box ">
	<el-row :gutter="20" class="nav-box" style="background-color: transparent;">
		<el-col :span="12" >
			<div class="grid-content bg-purple staff-index-count" >
				<img src="/images/asset/1.png" style="width: 120px;height: 120px;"/>
				<span >我的资产：{{assetCount}}</span>
				<router-link :to="{path:'/Asset/staffAsset',query:{main_title:'资产'}}">
					
				  <el-button type="primary">查看详情</el-button>
				</router-link>
			</div>
		</el-col>
		<el-col :span="12" >
			<div class="grid-content bg-purple staff-index-count">
				<img src="/images/asset/2.png" style="width: 120px;height: 120px;"/> 
				<span>我发起的：{{approCount}}</span>
				<router-link :to="{path:'/AssetApproval/index',query:{main_title:'审批'}}">
					<el-button type="primary">查看详情</el-button>
				 </router-link>
			</div>
		</el-col>
	</el-row>
	<el-row :gutter="20" class="nav-box" style="padding: 0px 10px;">
		<el-col :span="24" style="" style="background-color: white;border-radius: 5px;">
			<!-- <div class="grid-content bg-purple" style="background-color: white;"> -->
				<div class="asset-title" >资产申请</div>
				<div style="width: 90%;margin: 0 auto;">
					<!-- <el-col :span="24" > -->
						<!-- <div class="grid-content bg-purple">  -->
							  <el-col :span="3" style="height: 100px;"  v-for="item in assetMenuList">
								 <div class="grid-content ">
										<div style="text-align: center;" class="approval-item" @click="handleAdd(item.id,item.name,item.apply_type)"  >
											<el-image style="width: 50px;height: 50px;"  :src="item.page_icon"></el-image><br>
											<span class="desc">{{item.name}}</span>
										</div> 
								  </div>
							  </el-col>
						  <!-- </div> -->
					<!-- </el-col> -->
				</div>
				<div class="asset-title">耗材申请</div>
				<div style="width: 90%;margin: 0 auto;">
					<!-- <el-col :span="24" style="width: 90%;margin: 0 auto;"> -->
						  <el-col :span="3" style="height: 150px;"  v-for="item in cseMenuList">
							  <div class="grid-content ">
								  <div class="remind-item" style="text-align: center;" @click="handleAdd(item.id,item.name,item.apply_type)">
									<el-image style="width: 50px;height: 50px;"  :src="item.page_icon"></el-image><br>
										<span class="desc">{{item.name}}</span>
								  </div> 
							  </div>
						  </el-col>				  
					<!-- </el-col> -->
				</div>
			<!-- </div> -->
		</el-col>
		
	</el-row>
	<AddVue ref="childDialog" ></AddVue>
</div>
</template>

<script>
import AddVue from './Common/StaffAdd.vue'
export default {
	components:{
	    AddVue
	},
    data() {
        return {
			assetMenuList:[],
			cseMenuList:[],
			assetCount:0,
			approCount:0
        }
    },
    created() {
		 this.getData()
    },
    mounted() {
		
    },
    methods:{
		getData(){
			this.$api.get('assetList/staffIndex',null,res=>{
				if(res.code == 200){
					this.assetMenuList =  res.data.asset_menu_list
					this.cseMenuList =  res.data.cse_menu_list
					this.assetCount = res.data.asset_count
					this.approCount = res.data.appro_count
				}
			})
		},
		handleAdd(card_id,card_name,type){
			this.$nextTick(()=>{
				this.$refs.childDialog.cardName = card_name
				this.$refs.childDialog.applyType  = type
				this.$refs.childDialog.handleAdd()
			})
			
		},
    },
}
</script>
<style scoped lang="scss">
	.overview-box{
		background-color: #f7f9fa ;
		height: 100%;
		padding-top: 10px;
		.nav-box{
			// background-color: white;
			width: 96%;
			margin: 0 auto !important;
			border-radius: 10px;
			margin-bottom: 20px !important;
			.asset-title{
				text-indent: 20px;
				width: 100%;
				display: inline-block;
				height: 40px;
				line-height: 40px;
				font-size: 18px;
				font-weight: 700;
				color: #606266;
			}
			.desc{
				font-size: 12px;
				color: #303133; 
			}
			
			.staff-index-count{
				position: relative;
				background-color: white;
				height: 200px;
				line-height: 200px;
				display: flex;
				align-items: center;
				border-radius: 5px;
				span{
					color: #409EFF; 
					font-size:26px;
					font-weight: bolder;
					margin-left: 10px;
				}
				.el-button{
					position: absolute;
					right: 30px;
				}
			}
		}
		
		 .el-row {
		    margin-bottom: 20px;
		    &:last-child {
		      margin-bottom: 0;
		    }
		  }
		
	}

</style>