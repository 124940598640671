<template>
<div>
		<el-form  label-position="top"  ref="form" :model="form" prop="" >	
				<el-row class="field-content">
						<div v-for="(item,childIndex) in form" >
						  <el-col :span="8"  v-if="item.type !== 1 && item.type !== 4"> 
							  <el-form-item  :label="item.title" :required="item.required_status?true:false">
								  	<!-- 普通文本 -->
									<el-input  v-if="item.type < 1" style="width: 280px;" v-model="item.value" 
										:placeholder="item.title" ></el-input>
									<!-- 金额 -->
									 <el-input-number  v-if="item.type == 11" v-model="item.value" :precision="2" :step="0.1" :min="1"  controls-position="right"></el-input-number>
									<!-- 下拉框 -->
									<!-- 部门、成员数据下拉 -->
									<template  v-if="item.type == 9">
										<el-cascader
											 v-if="item.field_name == 'dept_id'"
											v-model="item.value"   
											 :props="props"  
											:options="deptList"  
											  @change="handleChangeDept"
											  ref="cascader"  
											></el-cascader> 
										<!-- 部门成员 -->
										<el-select filterable v-else-if="item.field_name == 'user_id'" v-model="item.value"   placeholder="请选择"  style="width: 280px;">
												 <el-option
												  v-for="admin in adminList"
												 :label="admin.name"
												 :value="admin.id">
												 </el-option>
										</el-select>
										<!-- 调出/调入管理员 -->
										<el-select filterable  v-else-if="item.field_name == 'out_admin_id' 
												|| item.field_name == 'in_admin_id'" 
												v-model="item.value"   
												 placeholder="请选择"  style="width: 300px;">										
												 <el-option
												 v-for="admin in adminList"
												:label="admin.name" 
												:value="admin.id">
												</el-option> 
										</el-select>
										<el-select  v-if="selectionStatus && item.field_name == 'location_id'"  ref="treeSelect"
											v-model="item.temp_select_name" placeholder="请选择" clearable 
											 @change="(val)=>handleSelect(val,index,childIndex)">
												<el-option value="0" label="无上级">无上级</el-option>
												<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
												 highlight-current ref="tree" 
												 @node-click="(val)=>handleTreeNodeClick(val,index,childIndex)"> </el-tree>
										</el-select>
										<el-select v-if="
													item.field_name !== 'out_admin_id' 
													&& item.field_name !== 'in_admin_id'
													&&  item.field_name !== 'dept_id'
													&&  item.field_name !== 'user_id'
													&&  item.field_name !== 'location_id'
													" v-model="item.value"   placeholder="请选择"  style="width: 280px;">
										  <el-option v-for="(cItem,cIndex) in item.content" :label="cItem.name" :value="cIndex">
										  </el-option>
										</el-select>
									</template>
									<!-- 日期选择 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 6"
										v-model="item.value"
										type="date"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 时间日期 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 5"
										v-model="item.value"
										type="datetime"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 金额 -->
									<el-input-number v-if="item.type == 1" v-model="item.value" :precision="2" :step="0.1" :max="10"></el-input-number>
							  </el-form-item>   
						  </el-col>
						   <el-col :span="24" v-if="item.type == 1 || item.type == 4">
							   <!-- 多行文本 -->
							   <el-form-item v-if="item.type == 1" :label="item.title"
									:required="item.required_status?true:false">
									<el-input type="textarea" rows="5"  v-model="item.value" placeholder="" ></el-input>
							   </el-form-item> 
							   <el-form-item v-if="item.type == 4" :label="item.title" 
									:required="item.required_status?true:false">
										<el-upload
										  action="https://jsonplaceholder.typicode.com/posts/"
										  list-type="picture-card"
										 >
										  <i class="el-icon-plus"></i>
										</el-upload>
								</el-form-item> 						
							</el-col>
					</div>
				</el-row>
		</el-form>
</div>
</template>

<script>
export default {
	props:['formData','cate'],
	inject:['reload'],
    data() {
        return {
			form:this.formData,
			dialogVisible:false,
			checkboxGroup:[],
			dialogAddVisible:false,
			selectionStatus:true,
			cateTreeData:this.cate,
			locationTreeData:[],
			groupData:[], 
			fieldData:[],
			cardName:'',
			cardType:2,
			form:{},
			theadData:[],
			// tableData:this.tableData,
			deptList:[],
			deptId:'',
			dialogTitle:'',
			addUrl:'',
			editUrl:'',
			storageType:0,
			assetCodeStatus:false,
			newRow:{},
			treePorps:{
				label:'name',
			},
			props:{
				value:'id',
				label:'name',
			},
			tableStatus:true,
			adminList:[],		//管理员列表
			staffStorageId:0,		//员工申请的单据id 传递时代表从员工单据里请求操作 后续绑定关联
			admin_status:1,
        }
    },
    created() {
		console.log(this.form)
    },
	watch: {
		// 'tableData'() {
		// 	this.tableStatus =  false
		// 	console.log('change-table')
		// 	this.$nextTick(()=>{
		// 		this.tableStatus  = true
				
		// 	})
		// },
	},
    mounted() {
		
    },
    methods:{
		// handleAdd(id=0){ 
		// 	if (id < 1) { 
		// 		// console.log('s_type:',this.storageType,',card_name:',this.cardName) 
		// 		this.dialogTitle =  '添加'+this.cardName
		// 		this.$api.get('AssetForm/getFieldAndTable', {
		// 			name:this.cardName,
		// 			type:this.cardType
		// 		}, res => {
		// 			if (res.code == 200) {
		// 				this.groupData =  res.data.group_data
		// 				this.theadData =  res.data.thead_data
		// 				if(this.storageType == 1){		
		// 					if(this.staffStorageId < 1 || this.tableData.length < 1){
		// 						this.tableData =  res.data.empty_table_data
		// 					}
		// 					this.newRow =  res.data.empty_table_data[0]
		// 				}
		// 				this.assetCodeStatus = res.data.asset_code_status?true:false	
									
		// 				this.checkboxGroup =  res.data.default_checked
		// 				this.$api.get('Department/deptList',null,deptRes=>{
		// 					if(deptRes.code == 200){
		// 						this.deptList =  deptRes.data.list
		// 					}
		// 				})
						
		// 				//获取资产位置和资产分类
		// 				this.$api.get('AssetCategory/treeData',null,res=>{
		// 				    if(res.code == 200){
		// 						this.cateTreeData =  res.data.tree_list
		// 					}
		// 				})
						
		// 				//管理员列表
		// 				this.$api.get('admin/getDeptAdmin',null,res=>{
		// 				     if(res.code == 200){
		// 						this.adminList =  res.data.list
		// 				     }else{
		// 				         this.$message.error(res.msg)
		// 				     }
		// 				 })

						
						
		// 				this.$api.get('AssetLocation/treeData',null,res=>{
		// 				    if(res.code == 200){
		// 						this.locationTreeData =  res.data.tree_list
		// 						// console.log('location:',this.locationData)
		// 					}
		// 				})
		// 				this.dialogAddVisible = true
		// 			}
		// 		})
		// 	} else {
		// 		// this.handleEdit(id)
		// 	}
		// },
		
		
		//添加新行
		addNewRow(index,table_data){
			this.tableStatus = false
			this.$nextTick(()=>{
				this.tableStatus = true
				this.tableData.push(this.newRow)
			})
		},
		
		//监听资产分类/位置选择
		handleNodeClick(val,index,name){
			this.tableStatus = false
			this.$nextTick(()=>{
					this.tableStatus = true
					if(name == 'location_id'){
						this.tableData[index]['location_name'] = val.name
						this.tableData[index]['location_id'] = val.id
					}
					if(name == 'cate_id'){
						this.tableData[index]['cate_name'] = val.name
						this.tableData[index]['cate_id'] = val.id
					}
					if(name == 'dept_id'){
						this.tableData[index]['dept_name'] = val.name
						this.tableData[index]['dept_id'] = val.id
						
						this.$api.get('admin/getDeptAdmin',{dept_id:val.id},res=>{
						     if(res.code == 200){
								this.adminList =  res.data.list
						     }else{
						         this.$message.error(res.msg)
						     }
						 })
					}
			})
		},
		handleTreeNodeClick(data,index,childIndex){
			this.selectionStatus =  false
			this.$nextTick(()=>{
				this.selectionStatus =  true
				this.groupData[index]['data'][childIndex]['value'] = data.id
				this.groupData[index]['data'][childIndex]['temp_select_name'] = data.name
				
				// console.log('groupdata:',this.groupData)
			})
		},
		handleSelectAsset(){
			this.$nextTick(()=>{
				this.$refs.selectAsset.storageType =  this.storageType
				this.$refs.selectAsset.showAsset(this.tableData) 
			})
		
		},
		deleteRow(index, rows) {
			rows.splice(index, 1);
		},
		
		//监听部门选择 更新部门成员数据
		handleChangeDept(value){
			let dept_id = value[value.length-1]    
			 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
				  if(res.code == 200){  
						this.adminList =  res.data.list
				  }else{
					  this.$message.error(res.msg)
				  } 
			  })
		},

		handleChangeSelect(val){
			this.deptId  = val
		},
		
    },
}
</script>
<style lang="scss">
	.storage-table-box{
		.cell{
			padding: 0 !important;
			text-align: center;
		}
		.add-table-row-box{
			// width: 120px;
			.el-input__inner{
				border: none !important;
			}
		}
	}

</style>
<style scoped lang="scss">
	.select-asset{
		color: #409EFF;
		font-weight: 600;
		font-size: 14px;
		text-align: right;
	}
	
</style>