var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"form",attrs:{"label-position":"top","model":_vm.form,"prop":""}},[_c('el-row',{staticClass:"field-content"},_vm._l((_vm.form),function(item,childIndex){return _c('div',[(item.type !== 1 && item.type !== 4)?_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":item.title,"required":item.required_status?true:false}},[(item.type < 1)?_c('el-input',{staticStyle:{"width":"280px"},attrs:{"placeholder":item.title},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),(item.type == 11)?_c('el-input-number',{attrs:{"precision":2,"step":0.1,"min":1,"controls-position":"right"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),(item.type == 9)?[(item.field_name == 'dept_id')?_c('el-cascader',{ref:"cascader",refInFor:true,attrs:{"props":_vm.props,"options":_vm.deptList},on:{"change":_vm.handleChangeDept},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):(item.field_name == 'user_id')?_c('el-select',{staticStyle:{"width":"280px"},attrs:{"filterable":"","placeholder":"请选择"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((_vm.adminList),function(admin){return _c('el-option',{attrs:{"label":admin.name,"value":admin.id}})}),1):(item.field_name == 'out_admin_id' 
												|| item.field_name == 'in_admin_id')?_c('el-select',{staticStyle:{"width":"300px"},attrs:{"filterable":"","placeholder":"请选择"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((_vm.adminList),function(admin){return _c('el-option',{attrs:{"label":admin.name,"value":admin.id}})}),1):_vm._e(),(_vm.selectionStatus && item.field_name == 'location_id')?_c('el-select',{ref:"treeSelect",refInFor:true,attrs:{"placeholder":"请选择","clearable":""},on:{"change":function (val){ return _vm.handleSelect(val,_vm.index,childIndex); }},model:{value:(item.temp_select_name),callback:function ($$v) {_vm.$set(item, "temp_select_name", $$v)},expression:"item.temp_select_name"}},[_c('el-option',{attrs:{"value":"0","label":"无上级"}},[_vm._v("无上级")]),_c('el-tree',{ref:"tree",refInFor:true,attrs:{"node-key":"id","data":_vm.locationTreeData,"props":_vm.treePorps,"highlight-current":""},on:{"node-click":function (val){ return _vm.handleTreeNodeClick(val,_vm.index,childIndex); }}})],1):_vm._e(),(
													item.field_name !== 'out_admin_id' 
													&& item.field_name !== 'in_admin_id'
													&&  item.field_name !== 'dept_id'
													&&  item.field_name !== 'user_id'
													&&  item.field_name !== 'location_id'
													)?_c('el-select',{staticStyle:{"width":"280px"},attrs:{"placeholder":"请选择"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((item.content),function(cItem,cIndex){return _c('el-option',{attrs:{"label":cItem.name,"value":cIndex}})}),1):_vm._e()]:_vm._e(),(item.type == 6)?_c('el-date-picker',{staticStyle:{"width":"280px"},attrs:{"type":"date","placeholder":"选择日期"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),(item.type == 5)?_c('el-date-picker',{staticStyle:{"width":"280px"},attrs:{"type":"datetime","placeholder":"选择日期"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),(item.type == 1)?_c('el-input-number',{attrs:{"precision":2,"step":0.1,"max":10},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e()],2)],1):_vm._e(),(item.type == 1 || item.type == 4)?_c('el-col',{attrs:{"span":24}},[(item.type == 1)?_c('el-form-item',{attrs:{"label":item.title,"required":item.required_status?true:false}},[_c('el-input',{attrs:{"type":"textarea","rows":"5","placeholder":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(item.type == 4)?_c('el-form-item',{attrs:{"label":item.title,"required":item.required_status?true:false}},[_c('el-upload',{attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","list-type":"picture-card"}},[_c('i',{staticClass:"el-icon-plus"})])],1):_vm._e()],1):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }